@import 'Colours';

.authLandingContainer {  
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 30px 0;
    text-align: center;

  h1 {
    margin-bottom: 10px;
  }
  p {
    font-size: 1.2rem;
  }
}

.oAuthBtn {
  margin-top: 30px;
  background: #FFFFFF;
  border: 3px solid;
  border-radius: 100px;
  padding: 5px 25px;
  height: 3em;
  color: black;
  
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  p {
    font-weight: 500;
    margin-left: 10px;
  }

  &:hover {
    cursor: pointer;
    background-color: transparent !important;
    color: black !important;
    border-color: #00B7E2 !important;
    text-decoration: none !important;
  }

  &.oAuthBtnXero {
    border-color: #00B7E2;
  }
}

.authForm {
  padding: 50px 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &.forgotPasswordForm{
    .formTitle {
      font-size: 25px;
      margin-bottom: 0;
      font-weight: 400;
    }
    .subtitle {
      font-size: 0.9rem;
      margin-bottom: 25px;
      opacity: 0.9;
    }
  }

  .formTitle {
    font-size: 25px;
    margin-bottom: 25px;
    font-weight: 500;

    &.loginFormTitle {
      margin-bottom: 5px;
    }
  }

  .subtitle {
    font-size: 0.9rem;
    opacity: 0.9;
  }

  .email {
    font-weight: 500;
  }

  .marginSeperator {
    margin-right: 0.25rem!important;
    margin-left: 0.25rem!important;
  }

  .fullButton {
    width: 100%;
    margin-top: 1.5em;
  }

  .smallButton {
    height: 1.9em;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 9pt;
    margin: 0.6em 0.6em 0.6em 0;
  }

  .topButton {
    margin-bottom: 1.5em;
  }

  .altLink{
    margin-top: 1.5em;
    font-size: 11.5pt;
  }

  .formContainer {
    border-radius: 3px;
    padding-top: 5px;
    margin: 0 10px;
    width: 32em;
    display: flex;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.116);
    background-color: #FAFAFA;
  }

  .fieldsContainer {
    margin: 40px 50px;
    
    @media screen and (max-width: 500px) {
      margin: 20px;
    }
  }

  .disableBtn {
    opacity: 0.7;
    &:hover {
      background-color: $paysimply-blue;
    }
  }

  .textSuccess {
    color: $state-success;
  }
  .bgSuccess {
    background-color: $state-success;
  }
  .textDanger {
    color: $state-danger;
    margin: 0 !important
  }
  .flexWrap {
    flex-wrap: wrap !important;
  }
  .bgDanger {
    background-color: $state-danger;
  }
  .forgotPasswordLink {
    font-size: 10pt;
  }
  
  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .flexRow.spaceBetween {
    justify-content: space-between;
  }

  .emailResendWaitTxt {
    line-height: 1rem;
    font-size: 90%;
  }

  .resendAlertText {
    margin-bottom: 0!important;
    margin-right: 0.25rem!important;
    margin-left: 0.25rem!important;
  }

  .googleRecaptchaDisclaimer {
    font-size: 11px;
    margin-bottom: 10px;
    margin-top: 7px;
  }
}