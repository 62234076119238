@import 'Colours';

.alert {
  border-radius: 5px;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  &.full {
    width: 100%;
  }

  &.error {
    border: 3px solid $state-danger;
    margin-bottom: 1rem;
  }

  &.success {
    border: 3px solid $state-success;
    margin-bottom: 1rem;
  }

  &.info {
    border: 3px solid $paysimply-blue;
    margin-bottom: 1rem;

    h2 {
      font-size: 1.1rem;
      margin-bottom: 5px;
    }
    p {
      opacity: 0.9;
      font-size: 0.9rem;
    }

    .alertIcon {
      color: $paysimply-blue;
      padding-right: 5px;
    }
  }
  .textSuccess {
    color: $state-success;
  }
  .textDanger {
    color: $state-danger;
    margin: 0 !important
  }

  .bgInfo {
    background-color: $state-info;
  }
  .bgSuccess {
    background-color: $state-success;
  }
  .bgDanger {
    background-color: $state-danger;
  }

  p {
    margin-bottom: 0;
  }

  .sub {
    margin-top: 0.2rem;
    font-size: 90%;
    line-height: 1.2;
  }

  .alertText{
    margin-left: 0.5rem!important;
  }
}
